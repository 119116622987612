import { computed } from "@vue/composition-api"
import { currentRoute, router } from "@/services/Router"
import { cloneDeep } from "lodash"
import { TimeRange } from "./TimeService"

/** 分頁 */
export const page = computed({
    get: () => {
        const query = currentRoute.value!.query
        return query.p ? parseInt(query.p as string) : 1
    },
    set: page => {
        const clonedQuery = cloneDeep(currentRoute.value!.query)
        clonedQuery.p = page.toString()
        router.push({
            path: currentRoute.value!.path,
            query: clonedQuery
        })
    }
})

/** 每頁數量 */
export const itemsPerPage = computed({
    get: () => {
        const query = currentRoute.value!.query
        return query.ipp ? parseInt(query.ipp as string) : 20
    },
    set: itemsPerPage => {
        const clonedQuery = cloneDeep(currentRoute.value!.query)
        clonedQuery.ipp = itemsPerPage.toString()
        router.push({
            path: currentRoute.value!.path,
            query: clonedQuery
        })
    }
})

/** 關鍵字 */
export const keyword = buildStringFilter("k")

/** 建立字串型篩選器 */
export function buildStringFilter(index: string) {
    return computed<string | null>({
        get: () => {
            const query = currentRoute.value!.query
            return (query[index] as string) ?? null
        },
        set: value => {
            const clonedQuery = cloneDeep(currentRoute.value!.query)
            delete clonedQuery.p
            if (!value) delete clonedQuery[index]
            else clonedQuery[index] = value
            router.push({
                path: currentRoute.value!.path,
                query: clonedQuery
            })
        }
    })
}

/** 建立數字型篩選器 */
export function buildNumberFilter(
    index: string,
    defaultValue: number | null = null
) {
    return computed<number | null>({
        get: () => {
            const query = currentRoute.value!.query
            return query[index] === undefined
                ? defaultValue
                : query[index] === ""
                ? null
                : parseInt(query[index] as string)
        },
        set: value => {
            const clonedQuery = cloneDeep(currentRoute.value!.query)
            if (value === null) clonedQuery[index] = ""
            else clonedQuery[index] = value.toString()
            router.push({
                path: currentRoute.value!.path,
                query: clonedQuery
            })
        }
    })
}

/** 建立數字陣列型篩選器 */
export function buildNumberArrayFilter(
    index: string,
    defaultValue: number[] = []
) {
    return computed<number[]>({
        get: () => {
            const query = currentRoute.value!.query
            return query[index] === undefined
                ? defaultValue
                : query[index] === ""
                ? []
                : typeof query[index] === "string"
                ? [parseInt(query[index] as string)]
                : (query[index] as string[]).map(s => parseInt(s))
        },
        set: value => {
            const clonedQuery = cloneDeep(currentRoute.value!.query)
            if (value.length === 0) clonedQuery[index] = ""
            else clonedQuery[index] = value.map(n => n.toString())
            router.push({
                path: currentRoute.value!.path,
                query: clonedQuery
            })
        }
    })
}

/** 建立布林型篩選器 */
export function buildBooleanFilter(
    index: string,
    defaultValue: boolean | null = null
) {
    return computed<boolean | null>({
        get: () => {
            const query = currentRoute.value!.query
            return query[index] === undefined
                ? defaultValue
                : query[index] === "true"
        },
        set: value => {
            const clonedQuery = cloneDeep(currentRoute.value!.query)
            delete clonedQuery.p
            if (value === null) delete clonedQuery[index]
            else clonedQuery[index] = value.toString()
            router.push({
                path: currentRoute.value!.path,
                query: clonedQuery
            })
        }
    })
}

/** 建立時間區間型篩選器 */
export function buildTimeRangeFilter(index: string) {
    return computed<TimeRange>({
        get: () => {
            const query = currentRoute.value!.query
            const start = (query[`${index}_s`] as string) || null
            const end = (query[`${index}_e`] as string) || null
            return {
                start,
                end
            }
        },
        set: value => {
            const clonedQuery = cloneDeep(currentRoute.value!.query)
            delete clonedQuery.p
            if (value.start) clonedQuery[`${index}_s`] = value.start
            else delete clonedQuery[`${index}_s`]
            if (value.end) clonedQuery[`${index}_e`] = value.end
            else delete clonedQuery[`${index}_e`]
            router.push({
                path: currentRoute.value!.path,
                query: clonedQuery
            })
        }
    })
}

/** 建立時間區間型篩選器 */
export function buildNumberRangeFilter(index: string) {
    return computed<NumberRange>({
        get: () => {
            const query = currentRoute.value!.query
            const start = (query[`${index}_s`] as string) || null
            const end = (query[`${index}_e`] as string) || null
            return <NumberRange>{
                start: start === null ? null : parseFloat(start),
                end: end === null ? null : parseFloat(end)
            }
        },
        set: value => {
            const clonedQuery = cloneDeep(currentRoute.value!.query)
            delete clonedQuery.p
            if (value.start) clonedQuery[`${index}_s`] = value.start.toString()
            else delete clonedQuery[`${index}_s`]
            if (value.end) clonedQuery[`${index}_e`] = value.end.toString()
            else delete clonedQuery[`${index}_e`]
            router.push({
                path: currentRoute.value!.path,
                query: clonedQuery
            })
        }
    })
}

export interface NumberRange {
    start: number | null
    end: number | null
}












import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import { keyword } from "@/services/QueryParmsService"
export default defineComponent({
    name: "KeywordInput",
    props: {
        placeholder: String,
        dense: Boolean
    },
    setup() {
        const state = reactive({
            keywordInput: keyword.value,
        })

        function setKeyword() {
            keyword.value = state.keywordInput
        }
        return {
            ...toRefs(state),
            setKeyword,
        }
    },
})

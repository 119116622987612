





















































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api"
import Qrcode from "@/components/Qrcode.vue"
import SpaceObjectLocation from "@/components/SpaceObjectLocation.vue"
import KeywordInput from "@/components/KeywordInput.vue"
import {
    page,
    itemsPerPage,
    keyword,
    buildNumberFilter,
} from "@/services/QueryParmsService"
import { currentRoute } from "@/services/Router"
import {
    ButtonResult,
    closeDialog,
    DialogButtonType,
    DialogLevel,
    errorDialog,
    showDialog,
    successDialog,
} from "@/services/DialogService"
import {
    QrCodeSortMethod,
    getQrCodes,
    deleteQrCode,
    SpaceObjectCache,
    SpaceObjectDTO,
    saveQrCodes,
    downloadQrCodeZip,
} from "@/services/SpaceObjectsService"
import { env } from "@/env"
import lodash from "lodash"

export default defineComponent({
    name: "QrCodeList",
    components: {
        KeywordInput,
        Qrcode,
        SpaceObjectLocation,
    },
    setup() {
        const sortMethod = buildNumberFilter("s", QrCodeSortMethod.Id)
        const state = reactive({
            items: [] as {
                id: string
                subject: string
                navigationPlace: SpaceObjectDTO | null
                unlockPlaces: (SpaceObjectDTO | null)[]
            }[],
            total: 0,
            loading: false,
        })

        const totalPages = computed(() =>
            Math.ceil(state.total / itemsPerPage.value)
        )

        function searchQrCodes(pagination = true) {
            return getQrCodes({
                skip: pagination
                    ? itemsPerPage.value * (page.value - 1)
                    : undefined,
                take: pagination ? itemsPerPage.value : undefined,
                keyword: keyword.value,
                sort: sortMethod.value,
            })
        }

        async function loadData() {
            state.loading = true
            const { total, items } = await searchQrCodes()
            const placeIds = lodash(items)
                .map((c) =>
                    [c.navigationPlaceId].concat(
                        c.unlockSettings.map((s) => s.placeId)
                    )
                )
                .flatten()
                .filter((id) => !!id)
                .uniq()
                .value() as string[]

            await SpaceObjectCache.updateByIds(placeIds)

            state.items = items.map((c) => ({
                id: c.id,
                subject: c.subject,
                navigationPlace: c.navigationPlaceId
                    ? SpaceObjectCache.get(c.navigationPlaceId) ?? null
                    : null,
                unlockPlaces: c.unlockSettings.map(
                    (u) => SpaceObjectCache.get(u.placeId) ?? null
                ),
            }))
            state.total = total
            state.loading = false
        }

        watch(
            () => currentRoute.value!.query,
            () => {
                loadData()
            }
        )

        loadData()

        async function removeMission(id: string) {
            const { button: confirmDelete } = await errorDialog(
                `確認要刪除?`,
                undefined,
                DialogButtonType.ConfirmCancel
            )
            if (confirmDelete === ButtonResult.Cancel) return
            try {
                await deleteQrCode(id)
                successDialog("成功刪除")
                loadData()
            } catch (error) {
                console.error(error)
                errorDialog("刪除失敗，請聯絡系統維護人員")
            }
        }

        const sortOptions = [
            {
                text: "ID",
                value: QrCodeSortMethod.Id,
            },
            {
                text: "主旨",
                value: QrCodeSortMethod.Subject,
            },
        ]

        function genQrCodePayload(id: string) {
            return `${env.QR_CODE_LINK_PREFIX}${id}`
        }

        async function exportQrCodes() {
            const { items } = await searchQrCodes(false)
            saveQrCodes(items)
        }

        async function downloadQrCodeWithDialog() {
            showDialog(
                "QR Code 產製中",
                "請稍後",
                DialogLevel.Info,
                DialogButtonType.None
            )
            await downloadQrCodeZip()
            closeDialog()
        }

        return {
            ...toRefs(state),
            page,
            itemsPerPage,
            totalPages,
            loadData,
            removeMission,
            sortOptions,
            sortMethod,
            genQrCodePayload,
            exportQrCodes,
            downloadQrCodeWithDialog,
        }
    },
})
